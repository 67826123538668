import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 

import Loading from '../../components/chrome/loading';
import { Link } from 'react-router-dom';
import AssetItem from '../../components/assets/item';

import _ from 'lodash';
import { fetchTopicsList, readTopicModule, startTopic } from '../../actions/topics';
import { fetchQuizzesList } from '../../actions/quizzes';
import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import handleViewport from 'react-in-viewport';
import ReactTooltip from 'react-tooltip';
import moment from 'moment-timezone';
import 'moment/min/locales';
import CountUp from 'react-countup';

class TopicView extends Component {

	constructor(props){
		super(props);
						
		const { id, topic } = this.props.match.params;
		
        if(!id){
	       	this.props.history.push('/modules');
		}else if(!topic){
			this.props.history.push(`/modules/${id}/1`);
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'TOPICS_VIEW')){
				this.props.history.push('/modules');
			}
		}
				
		this.state = {
			id: id,
			module: topic ? topic : 1,
			started: false
		}
		
		this.props.fetchQuizzesList();
		
		this.props.fetchTopicsList(id, () => {
		       	
	       	if(this.props.topics.topics && !this.props.topics.topics[id]){
				this.props.history.push('/modules');
			}else{
				this.updateDomElements();				
			}
       	});
       	
		this.updateDomElements();     
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}

	componentDidUpdate(){
		ReactTooltip.rebuild();
	}

	componentWillReceiveProps(nextProps){
				
		const { topic } = nextProps.match.params;
		
		if(topic && this.state.module !== topic){
			this.setState({
				module: topic
			});
		}
	}
	
	updateDomElements(){
				
		if(this.props.topics.topics && this.props.topics.topics[this.state.id]){
			const topic = this.props.topics.topics[this.state.id];		
			this.props.setSectionOptions(
				'modules', 
				topic.title,
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
			
			if(!this.state.started && !topic.started){
					
				this.setState({
					started: true
				}, () => {
					this.props.startTopic(this.state.id);
				});
			}
		}else{
			this.props.setSectionOptions(
				'modules', 
				'Modules',
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
		}
	}

	render(){
		
		const { topics } = this.props;
		
		if(!topics.topics || !topics.topics[this.state.id] || !this.props.quizzes.quizzes){
			return (
				<Loading />
			);
		}
		
		const topic = topics.topics[this.state.id];
		const module = topic.modules[this.state.module-1];
				
		if(!topic.started || !module){
			
			return (
				<Loading />
			);
		}
		
		const Read = (props: { inViewport: boolean }) => {
			const { inViewport, forwardedRef } = props;
			return (
				<div className="viewport-block" ref={forwardedRef}></div>
			);
		};
		
		const ReadBlock = handleViewport(Read);
		
		const quiz = (item, type) => {
						
			let ret = {
				icon: 'badge',
				tooltip: 'Take Quiz',
				class: ''
			}
				
			if(item.quiz_completed){
				ret.tooltip = `${item.quiz_score}%`;
				ret.icon = 'badge-check';
				ret.class = 'green';
			}else if(item.quiz_started){
				ret.tooltip = 'Complete Quiz';
			}
			
			return ret[type];
		}
			
		return (
			<div className="container">	
			
				{topic.completed && 
					
					<div className="row">

						<div className="col-xl-4 col-md-12">
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small `}>
									<i className={`fal fa-calendar`}></i>
								</span>
										
								<h3 className="c-text--subtitle">Completed</h3>
								<h2 className="counter">
									{moment(topic.completed.date).format('LLL')}
								</h2>
							</div>
						</div>
						
						{topic.quiz_pass_rate && topic.score && 
							<div className="col-xl-4 col-md-12">
								<div className="c-card equalize stats-box">
									<span className={`c-icon u-mb-small `}>
										<i className={`fal fa-badge-percent`}></i>
									</span>
									<h3 className="c-text--subtitle">Score</h3>
									<h2 className="counter">
										<CountUp 
											delay={0} 
											duration={1} 
											end={parseFloat(topic.score)} 
											separator="," 
											preserveValue={true}
											suffix="%"
										/>
									</h2>
								</div>
							</div>
						}
													
						{topic.quiz_pass_rate && topic.score && 	
						
							<div className="col-xl-4 col-md-12">
								<div className="c-card equalize stats-box">
									<span className={`c-icon u-mb-small `}>
										<i className={`fal fa-${(parseFloat(topic.score) >= parseFloat(topic.quiz_pass_rate)) ? 'check' : 'times'}`}></i>
									</span>
											
									<h3 className="c-text--subtitle">Result</h3>
									<h2 className="counter">
										{(parseFloat(topic.score) >= parseFloat(topic.quiz_pass_rate)) ? 'Pass' : 'Fail'}
									</h2>
									
									{(topic.certificate == 1 && parseFloat(topic.score) >= parseFloat(topic.quiz_pass_rate)) && 
										<Link to={`/modules/${topic.id}/${this.state.module}/achievement/${topic.id}`} className="c-btn c-btn--info u-mt-small">
											Certificate
										</Link>
									}
								</div>
							</div>
						}
					</div>
				}
							
				<div className="row">
					<div className="col-xl-4 col-md-12">
					
						<div className="c-card">
														
							<h4 className="u-mb-medium">{i18n.t('topics:content_title_topics')} </h4>				
										
							{_.map(topic.modules, (item, key) => {
																					
								return (
									<div 
										className={`c-card clickable reduced u-mb-small ${this.state.module-1 == key ? 'selected' : ''}`} 
										key={key}
										onClick={(e) => {
																						
											if(!e.target.href && !e.target.classList.contains('link')){
												this.props.history.push(`/modules/${this.state.id}/${key+1}`);
											}
										}}
									>
										<h6 className="split">
											{item.title}
											 
											 <div className="options">												
												{item.quiz_id && 
												 	<Link to={`/modules/${this.state.id}/${key+1}/quiz`} className={quiz(item, 'class')} >
														<i className={`link fal fa-${quiz(item, 'icon')}`} data-tip={quiz(item, 'tooltip')}></i>
													</Link>	
												}
												
												{item.read && 
													<i className="fal fa-check green" data-tip={`Read ${moment(item.read.date).format('LLL')}`}></i>
												}
											 </div>
										</h6>
										
										{item.summary && item.summary !== '' &&  
											<small>{item.summary}</small>
										}										
									</div>
								);
							})}
						</div>
					</div>				
					<div className="col-xl-8 col-md-12">
						
						<div className="c-card" key={module.id}>
														
							<h4 className="u-mb-medium">{module.title}</h4>				
							
							{_.map(module.content, (content, key) => {
																
								switch(content.type){
									
									case 'asset':
										return (
											<div 
												key={key} 
												className="u-mb-medium"
											>
												<AssetItem
													asset={{
														id: content.content,
														type: content.asset_type,
														filename: content.asset_url,
														extension: content.asset_extension
													}}
												/>
											</div>
										)
										break;
										
									case 'text':
									default:
										return (
											<div 
												key={key} 
												className="u-mb-medium"
												dangerouslySetInnerHTML={{__html: content.content }}
												onClick={(e) => {
													
													// OPEN REMOTE LINKS IN NEW WINDOW
													if(e.target && e.target.href){
																												
														if(new URL(document.baseURI).origin !== new URL(e.target.href, document.baseURI).origin){
															e.preventDefault();
															window.open(e.target.href);
														}
													}													
												}}
											/>
										)
										break;
									
								}							
							})}

							{module.quiz_id && 
								<Link to={`/modules/${this.state.id}/${this.state.module}/quiz`} className="c-card u-mt-medium u-mb-zero">
									<h4 className="u-mb-small">Topic Quiz</h4>	
									
									{module.quiz_completed && 
										<div className="row">
										
											<div className="col-xl-6 col-md-12">
												<div className="c-card equalize stats-box">
													<span className={`c-icon u-mb-small `}>
														<i className={`fal fa-calendar`}></i>
													</span>
															
													<h3 className="c-text--subtitle">Completed</h3>
													<h2 className="counter">
														{moment(module.quiz_completed.date).format('LLL')}
													</h2>
												</div>
											</div>
											<div className="col-xl-3 col-md-12">
												<div className="c-card equalize stats-box">
													<span className={`c-icon u-mb-small `}>
														<i className={`fal fa-badge-percent`}></i>
													</span>
													<h3 className="c-text--subtitle">Score</h3>
													<h2 className="counter">
														<CountUp 
															delay={0} 
															duration={1} 
															end={parseFloat(module.quiz_score)} 
															separator="," 
															preserveValue={true}
															suffix="%"
														/>
													</h2>
												</div>
											</div>
											<div className="col-xl-3 col-md-12">
												<button className="c-btn c-btn--info c-btn-outline">
													View Results
												</button>
											</div>
										</div>
									||
										<div className="row">
										
											<div className="col-xl-6 col-md-12">
												<div className="c-card equalize stats-box">
													<span className={`c-icon u-mb-small `}>
														<i className={`fal fa-calendar`}></i>
													</span>
															
													<h3 className="c-text--subtitle">Started</h3>
													<h2 className="counter">
														{module.quiz_started ? moment(module.quiz_started.date).format('LLL') : 'Not yet started'}
													</h2>
												</div>
											</div>
											<div className="col-xl-3 col-md-12">
												<div className="c-card equalize stats-box">
													<span className={`c-icon u-mb-small `}>
														<i className={`fal fa-check`}></i>
													</span>
													<h3 className="c-text--subtitle">Answered</h3>
													<h2 className="counter">
														<CountUp 
															delay={0} 
															duration={1} 
															end={parseFloat(module.quiz_questions_answered)} 
															separator="," 
															preserveValue={true}
														/>/{module.quiz_questions}
													</h2>
												</div>
											</div>
											<div className="col-xl-3 col-md-12">
												<button className="c-btn c-btn--info">
													{module.quiz_started ? 'Complete Quiz' : 'Start Quiz'}
												</button>
											</div>
										</div>
									}
								</Link>										
							}	
							
							<ReadBlock onEnterViewport={() => {
								if(!module.read){
									this.props.readTopicModule(this.state.id, module.id);
								}								
							}} />	
															
							<div className="row u-mt-medium">
							
								{topic.modules.length > 1 &&
									
									<React.Fragment>
										<div className="col-xl-6">
											{parseFloat(this.state.module) !== 1 && 
												<button 
													className="c-btn c-btn--info c-btn--outline"
													onClick={(e) => {
														this.props.history.push(`/modules/${this.state.id}/${parseFloat(this.state.module)-1}`);
													}}
												>
													<i className={`fal fa-chevron-left`}></i>
												</button>
											}
										</div>
										<div className="col-xl-6 u-text-right">
											{parseFloat(this.state.module) !== topic.modules.length && 
												<button 
													className="c-btn c-btn--info"
													onClick={(e) => {
														this.props.history.push(`/modules/${this.state.id}/${parseFloat(this.state.module)+1}`);
													}}
												>
													<i className={`fal fa-chevron-right`}></i>
												</button>
											}
										</div>
									</React.Fragment>
								}
							</div>										
						</div>	
					</div>
				</div>
				<ReactTooltip 
					effect="solid"
				/>
			</div>
		);
	}
}

function mapStateToProps({ topics, account, quizzes }, ownProps){
	return {
		topics,
		account,
		quizzes
	};
}

export default connect(
	mapStateToProps, {
		setSectionOptions,
		fetchTopicsList,
		fetchQuizzesList,
		readTopicModule,
		startTopic
	})(
	(TopicView)
);
