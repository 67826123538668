import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { LazyImage } from "react-lazy-images";
import AssetActions from "./actions";
import Truncate from 'react-truncate-string'
import { toggleAssetHeadline } from '../../actions/assets';
import { imageResize } from '../../helpers/s3';
import ReactTooltip from 'react-tooltip';
import i18n from '../../helpers/i18n'; 

class AssetGridItem extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			hoverGrid: false,
			hoverThumb: false
		}
	}
	
	onSelectItem(id){
		
		if(this.props.onSelectItem === 'disabled'){
			return;
		}else if(this.props.onSelectItem){
			this.props.onSelectItem(id);
		}else{
			let { asset } = this.props;
			this.props.history.push(`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/asset/${asset.id}`);
		}
	}
	
	displayBar(){
		
		let { asset } = this.props;
		
		let icon = 'fa-image-polaroid';
		let type = 'asset';
		let title = asset.name;
		let actions = this.props.actions ? this.props.actions : false;
		let locked = '';

		switch(asset.type){
			
			case 'image':
				icon = 'fal fa-image-polaroid';
				break;
			
			case 'video':
			case 'youtube':
			case 'vimeo':
				icon = 'fal fa-play';
				break;
				
			case 'document':
				icon = 'fal fa-file-word';
				break;
				
			case 'spreadsheet':
				icon = 'fal fa-file-excel';
				break;
				
			case 'presentation':
				icon = 'fal fa-file-powerpoint';
				break;
				
			case 'pdf':
				icon = 'fal fa-file-pdf';
				break;
				
			case 'audio':
				icon = 'fal fa-music';
				break;
			
			default:
				icon = 'fal fa-question-square';
				break;
		}
		
		
		if(asset.readonly === 1){
			
			switch(asset.type){
				
				default:
					locked = (
						<i className="fal fa-lock"></i>
					);
					break;
			}			
		}

		return (
			<div className={`info ${this.state.hoverGrid ? 'hover' : ''}`}>
				<h4>
					<Link 
						to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}${this.props.back ? '?b=' + this.props.back : ''}`}
						className={locked !== '' ? 'locked' : ''}
					>
						{locked}
						<Truncate text={title} truncateAt={100} />
					</Link>
				</h4>
				<div>
					
					{this.state.hoverGrid && 
						<AssetActions 
							asset={asset}
							actions={actions}
							type={type}
							back={this.props.parentPath ? this.props.back : false}
							{...this.props}
						/>
					}
					
					<Link 
						to={`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/${type}/${asset.id}${this.props.back ? '?b=' + this.props.back : ''}`}
					>
						<i className={icon}></i>
					</Link>
				</div>
			</div>
		);	
	}
	
	displaySub() {
		
		if(this.props.sub){
			
			return (
				<div className="sub">
					{this.props.sub}
				</div>
			);
		}
	}
	
	render(){
		
		let { asset } = this.props;
		let size = 3;
		let image = false;
		
		switch(asset.type){
			
			case 'product':
				image = imageResize(asset.image, 500, 275, 'contain');
				break;
			
			default:
				image = imageResize(asset.thumbnail, 500);
				break;
		}

		if(this.props.size){
			
			switch(this.props.size){
				
				case 'small':
					size = 2;
					break;
					
				case 'large':
					size = 4;
					break;
					
				default:
				
					break;
			}
		}
		
		let tip = null;
		
		if(asset.readonly === 0){
			tip = asset.headline === 1 ? i18n.t('assets:tooltip_headline_unset') : i18n.t('assets:tooltip_headline_set')
		}
		
		return (
			<div className={`scroller-col col-sm-6 col-lg-4 col-xl-${size}`}>
				<div 
					className={`c-card c-card-asset is-animated ${this.props.selected ? 'selected' : ''}`}
					onMouseEnter={() => { this.setState({ hoverGrid: true })}}
					onMouseLeave={() => { this.setState({ hoverGrid: false })}}
				>					
					<div
						className="thumbnail"
						onMouseEnter={() => { this.setState({ hoverThumb: true })}}
						onMouseLeave={() => { this.setState({ hoverThumb: false })}}
					>
						{asset.type === 'image' && 
							<React.Fragment>
								<i 
									ref={ref => this.tooltip = ref}
									className={`${asset.headline === 1 ? 'fas' : 'far'} ${this.state.hoverThumb ? 'highlight' : ''} ${asset.headlineSelection || asset.readonly === 0 ? 'change' : ''} fa-shield headline`}
									onClick={() => {										
										if(this.props.headlineChange){
											this.props.headlineChange();
										}else if(asset.readonly === 0){
											this.props.toggleAssetHeadline(asset.id, asset.headline === 1 ? 0 : 1);
										}
											
										ReactTooltip.hide(this.tooltip);
									}}
									data-tip={tip}
								></i>
								<ReactTooltip 
									effect="solid"
								/>
							</React.Fragment>
						}
						<LazyImage
							src={image}
							placeholder={({ imageProps, ref }) => (
								<div ref={ref} className="image selectable" onClick={() => { this.onSelectItem(asset.id) }}></div>
							)}
							actual={({ imageProps }) => (
								<div className={`image ${this.props.onSelectItem !== 'disabled' ? 'selectable' : ''}`} style={{ backgroundImage: `url(${imageProps.src})` }} onClick={() => { this.onSelectItem(asset.id) }}>
									
									{this.state.hoverThumb && this.props.onSelectItem !== 'disabled' &&
										<div className="hover">
											<div>
												<i className={`fal ${this.props.icon ? this.props.icon : 'fa-eye'}`}></i>
											</div>
										</div>
									}
								</div>
							)}
						/>
					</div>
					{this.displayBar()}
					{this.displaySub()}
				</div>
	        </div>
		);
	}
}

function mapStateToProps({ assets }, ownProps){
	return {
		assets
	};
}

export default withRouter(connect(mapStateToProps, { 
	toggleAssetHeadline
})(AssetGridItem));