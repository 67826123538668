// REWORK i18n.t params
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSectionOptions } from '../../actions/chrome';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { fetchSuggestions } from '../../actions/messages';
import { userOverview } from '../../actions/stats';
import TopicGridItem from '../../components/topics/grid_item';

import Loading from '../../components/chrome/loading';
import moment from 'moment';
import { checkPermission } from '../../helpers/permissions';
import 'moment/min/locales';
import { imageResize } from '../../helpers/s3';

class Dashboard extends Component {

	constructor(props){
		super(props);
		
		if(!checkPermission(this.props.account.permissions, 'DASHBOARD')){
			
			if(checkPermission(this.props.account.permissions, 'TOPICS')){
				this.props.history.push('/modules');
			}else if(checkPermission(this.props.account.permissions, 'REGIONAL')){
				this.props.history.push('/regional');
			}			
		}
		
		this.props.setSectionOptions(
			'dashboard', 
			i18n.t('dashboard:title', { name: this.props.account.profile.forename })
		);
		
		//this.props.fetchSuggestions();
		this.props.userOverview();
				
		moment.locale(i18n.language);
		
		let startDate = moment().subtract(1,'month');
		let endDate = moment();
		
		this.state = {
			date: {
	            startDate: startDate.toDate(),
	            endDate: endDate.toDate()
            },
            campaigns: {
	            draft: 'imported',
	            active: 'imported'
	        }
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	render() {
		
		const { stats } = this.props;
		
		if(!stats.overview){
			return (
				<Loading />
			);
		}
				
		return (
			<div className="container">
			
				<div className="row">
					<div className={`col-xs-12 col-xl-3`}>
						<Link to="/modules" className="c-card stats-box">
							<span className={`c-icon u-mb-small `}>
								<i className={`fal fa-graduation-cap`}></i>
							</span>
									
							<h3 className="c-text--subtitle">Pending Modules</h3>
							<h2 className="counter">
								<CountUp 
									delay={0} 
									duration={1} 
									end={stats.overview.topics.counts.pending} 
									separator="," 
									preserveValue={true}
								/>
							</h2>
						</Link>
						<Link to="/modules" className="c-card stats-box">
							<span className={`c-icon u-mb-small `}>
								<i className={`fal fa-circle`}></i>
							</span>
									
							<h3 className="c-text--subtitle">Incomplete Modules</h3>
							<h2 className="counter">
								<CountUp 
									delay={0} 
									duration={1} 
									end={stats.overview.topics.counts.incomplete} 
									separator="," 
									preserveValue={true}
								/>
							</h2>
						</Link>
						<Link to="/modules" className="c-card stats-box">
							<span className={`c-icon u-mb-small `}>
								<i className={`fal fa-check-circle`}></i>
							</span>
									
							<h3 className="c-text--subtitle">Completed Modules</h3>
							<h2 className="counter">
								<CountUp 
									delay={0} 
									duration={1} 
									end={stats.overview.topics.counts.completed} 
									separator="," 
									preserveValue={true}
								/>
							</h2>
						</Link>
						<Link to="/acheivements" className="c-card stats-box">
							<span className={`c-icon u-mb-small `}>
								<i className={`fal fa-award`}></i>
							</span>
									
							<h3 className="c-text--subtitle">Acheivements</h3>
							<h2 className="counter">
								<CountUp 
									delay={0} 
									duration={1} 
									end={stats.overview.achievements} 
									separator="," 
									preserveValue={true}
								/>
							</h2>
						</Link>
							
					</div>
					<div className={`col-xs-12 col-xl-9`}>
						<div className="c-card">							
							<h4>Latest Modules</h4>
							
							<div className="row u-pt-small topics">
								{_.map(stats.overview.topics.latest, (item, key) => {	
																				
									return (
										<TopicGridItem 
											key={key}
											topic={item}
											icon={'fa-eye'}
											size="large"
										/>						
									);
								})}
							</div>
							
							<Link to="/modules" className="c-btn c-btn--info">
								View All
							</Link>
						</div>
					</div>
				</div>
				
				
				
							
							
			</div>
		);
	}
}

function mapStateToProps({ account, brand, messages, assets, stats }, ownProps){
	return {
		account,
		brand,
		messages,
		assets,
		stats
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchSuggestions,
	userOverview
})(Dashboard);