import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import QuizzesToolbar from './toolbar';

import Loading from '../../components/chrome/loading';
import AssetPicker from '../../components/assets/picker';

import { startSubmission, stopSubmission } from '../../actions/forms';
import { fetchQuizzesList, saveQuizGeneral } from '../../actions/quizzes';

import _ from 'lodash';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';
import moment from 'moment-timezone';
import 'moment/min/locales';

var formName = 'formQuizSetup';

class QuizSetup extends Component {

	constructor(props){
		super(props);
						
		const { id } = this.props.match.params;
		
		this.state = {
            id: id,
            status: 'draft', 
            access: 'direct',
			format: 'flow',
			answers: 'end',
			image: false,
			public: true,
			date_launch: moment.tz(this.props.account.profile.timezone).add(1, 'hour').toDate(),
        }
        
        if(id){
	       	// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'QUIZZES_EDIT')){
				this.props.history.push('/quizzes');
			}
			
			this.props.fetchQuizzesList(id, () => {
		       	
		       	if(this.props.quizzes.quizzes && !this.props.quizzes.quizzes[id]){
					this.props.history.push('/quizzes');
				}else{
					this.updateDomElements();
				}
	       	});
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'QUIZZES_ADD')){
				this.props.history.push('/quizzes');
			}
			
			this.updateDomElements();
		}			
		
		moment.locale(i18n.language);
		this.props.stopSubmission(formName);		   
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
		
		let init = {
			status: this.state.status,
			format: this.state.format,
			answers: this.state.answers,
			date_launch: this.state.date_launch
		}
		
		if(this.props.quizzes.quizzes && this.state.id && this.props.quizzes.quizzes[this.state.id]){
		
			const quiz = this.props.quizzes.quizzes[this.state.id];			
			let date_launch = this.state.date_launch;
			let date_launch_set = false;
			
			if(quiz.date_launch !== null){
				date_launch = quiz.date_launch.date;
				date_launch_set = true;
			}
						
			init = quiz;
			init.date_launch = date_launch;
							
			this.setState({
				date_launch: moment.utc(date_launch, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate(),
				status: quiz.status,
				format: quiz.format,
				public: quiz.public == 1 ? true : false,
				answers: quiz.answers,
				image: quiz.image_id ? quiz.image_id : false,
				access: date_launch_set ? 'direct' : 'module'
			});	
			
			this.props.setSectionOptions(
				'quizzes', 
				quiz.title,
				[
					{
						url: '/quizzes',
						label: 'Quizzes'
					}
				]
			);			
		}else{
			this.props.setSectionOptions(
				'quizzes', 
				i18n.t('quizzes:settings_title'),
				[
					{
						url: '/quizzes',
						label: 'Quizzes'
					}
				]

			);
		}
		
		this.props.initialize(init);
	}
	
	onSubmit(values) {
				  	
	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  		  	
	  	posting.status = this.state.status;
	  	posting.format = this.state.format;
	  	posting.answers = this.state.answers;
	  	posting.image = this.state.image;
	  	
	  	if(this.state.access == 'direct'){
		  	posting.date_launch = moment(this.state.date_launch).format('YYYY-MM-DD HH:mm:ss');
	  	}else if(posting.date_launch){
		  	delete(posting.date_launch);
	  	}
	  	
	  	posting.public = this.state.public ? 1 : 0;

	  	this.props.saveQuizGeneral(formName, this.state.id, posting, (ret) => {
			
			this.props.setSectionOptions(
				'quizzes', 
				ret.quizzes[0].title,
				[
					{
						url: '/quizzes',
						label: 'Quizzes'
					}
				]
			);
			
			if(this.props.proceed){
				this.props.history.push(`/quizzes/${ret.quizzes[0].id}/questions`);	
			}
		});
	}
	
	renderPage(){
		
		const { quizzes } = this.props;
		
		if(this.state.id && (!quizzes.quizzes || !quizzes.quizzes[this.state.id])){
			return (
				<Loading />
			);
		}
		
		const quiz = this.state.id ? quizzes.quizzes[this.state.id] : false;
		
		return (
			<div className="container">				
				<div className="row">
					<div className="col-xl-8 col-md-12">
					
						<div className="c-card">
														
							<h4 className="u-mb-medium">{i18n.t('quizzes:settings_title_general')} </h4>				
		
							<div className="row">
								<div className="col-xl-6 col-md-12">
									<Field
										label={i18n.t('quizzes:settings_label_title')} 
										name="title"
										type="text"
										component={InputField}
									/>
								</div>
								<div className="col-xl-6 col-md-12">
									
									<Field
										label={i18n.t('quizzes:settings_label_status')} 
										name="status"
										type="suggest"
										options={[{
											label: 'Draft',
											value: 'draft'
										},
										{
											label: 'Approved',
											value: 'approved'
										},
										{
											label: 'Disabled',
											value: 'disabled'
										}]}
										selected={this.state.status}
										onChangeFunc={(selected) => {
											this.setState({
												status: selected.value
											});
										}}
										component={InputField}
									/>
								</div>
							</div>							
							
							<Field
								label={i18n.t('quizzes:settings_label_summary')} 
								name="summary"
								type="textarea-auto"
								maxLength={250}
								maxLengthCounter={true}
								component={InputField}
							/>
							
							<div className="row">
								<div className="col-xl-6 col-md-12">
									
									<Field
										label={i18n.t('quizzes:settings_label_access')} 
										name="access"
										type="suggest"
										options={[{
											label: 'Within Module or Direct',
											value: 'direct'
										},
										{
											label: 'Within Module Only',
											value: 'module'
										}]}
										selected={this.state.access}
										onChangeFunc={(selected) => {
											this.setState({
												access: selected.value
											});
										}}
										component={InputField}
									/>
								</div>
								<div className="col-xl-6 col-md-12">
									
									{this.state.access == 'direct' &&
										<Field
											label={i18n.t('quizzes:settings_label_date_launch')} 
											name="date_launch"
											type="datepicker"
											timepicker={true}
											disabled={this.state.status == 'disabled' ? true : false}
											selected={this.state.date_launch}
											onChangeFunc={(date) => { 
												this.setState({
													'date_launch': date
												}); 
											}}
											component={InputField}
											minDate={moment().toDate()}
										/>	
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-xl-6 col-md-12">
								<div className="c-card equalize">
									<h4 className="u-mb-medium">{i18n.t('quizzes:settings_title_style')} </h4>
							
									<Field
										label={i18n.t('quizzes:settings_label_format')} 
										name="format"
										type="suggest"
										options={[{
											label: 'One question at a time',
											value: 'flow'
										},
										{
											label: 'List of questions',
											value: 'list'
										}]}
										selected={this.state.format}
										onChangeFunc={(selected) => {
											this.setState({
												format: selected.value
											});
										}}
										component={InputField}
									/>
								
									<Field
										label={i18n.t('quizzes:settings_label_answers')} 
										name="answers"
										type="suggest"
										options={[{
											label: 'Display at end of the quiz',
											value: 'end'
										},
										/*{
											label: 'Display as each question answered',
											value: 'proceed'
										},*/
										{
											label: 'Do not display answers',
											value: 'none'
										}]}
										selected={this.state.answers}
										onChangeFunc={(selected) => {
											this.setState({
												answers: selected.value
											});
										}}
										component={InputField}
									/>
								</div>
							</div>
							<div className="col-xl-6 col-md-12">
								<div className="c-card equalize">
									<h4 className="u-mb-medium">{i18n.t('quizzes:settings_title_access')}</h4>
									
									<Field
										name="public"
										type="switch"
										labelSwitch={i18n.t('quizzes:settings_label_public')} 
										selected={this.state.public}
										onChangeFunc={(event) => {
											this.setState({
												public: event.target.checked
											});							
										}}
										component={InputField}
									/>							
								</div>
							</div>
						</div>
					</div>						
					
					<div className="col-xl-4 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('quizzes:settings_title_thumbnail')}</h4>
														
							<AssetPicker
								types={['image']}
								max={1}
								selected={this.state.image ? [{ type: 'asset', id: this.state.image }] : false}
								placeholder={i18n.t('quizzes:settings_thumbnail_empty')}
								onSelect={(selected) => {
									
									let image = false;
																		
									if(selected && selected.length > 0){
										image = selected[0].id;
									}
																		
									this.setState({
										image: image
									});
								}}
							/>
						</div>	
					</div>
				</div>
			</div>
		);
	}
	
	// TODO: option here for cover image to be selected
	
	render() {		
				
		const { handleSubmit } = this.props;
		const { quizzes } = this.props;
					
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
			
				<QuizzesToolbar 
					formName={formName}
					id={this.state.id}
					section="setup"
					history={this.props.history}
					quiz={(this.state.id && quizzes.quizzes && quizzes.quizzes[this.state.id]) ? quizzes.quizzes[this.state.id] : false}
				/>
				
				{this.renderPage()}
			</form>				
		);
	}
}

const validate = (values, props) => {	
		
	const errors = {};
	
	if (!values.title) {
		errors.title = 'Enter a quiz title!';
	}
	
	if (!values.status) {
		errors.status = 'Choose quiz status!';
	}
	
	if (!values.date_launch) {
		errors.date_launch = 'Choose quiz launch date!';
	}

	if (!values.summary) {
		errors.summary = 'Enter a quiz summary!';
	}
	
	if (!values.format) {
		errors.format = 'Choose quiz format!';
	}
	
	if (!values.answers) {
		errors.answers = 'Choose quiz answers format!';
	}
	
	return errors;
}

function mapStateToProps({ quizzes, forms, account }, ownProps){
	return {
		quizzes,
		proceed: forms.proceed,
		account
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchQuizzesList,
		saveQuizGeneral
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(QuizSetup)
);
