import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { LazyImage } from "react-lazy-images";
import Truncate from 'react-truncate-string'
import { imageResize } from '../../helpers/s3';

class TopicGridItem extends Component {

	constructor(props) {
		super(props);
		
		this.state = {
			hoverGrid: false
		}
	}

	render(){
		
		let { topic } = this.props;
		let size = 3;
				
		if(this.props.size){
			
			switch(this.props.size){
				
				case 'small':
					size = 2;
					break;
					
				case 'large':
					size = 4;
					break;
					
				default:
				
					break;
			}
		}
			
		return (
			<div className={`scroller-col col-sm-6 col-lg-4 col-xl-${size}`}>
				<Link
					to={`/modules/${topic.id}`}
					className="c-card c-card-topic is-animated"
					onMouseEnter={() => { this.setState({ hoverGrid: true })}}
					onMouseLeave={() => { this.setState({ hoverGrid: false })}}
				>					
					<div className="thumbnail">						
						<LazyImage
							src={imageResize(topic.image_filename ? topic.image_filename : `brands/${this.props.brand.details.directory}/general/placeholder-topic.png`, 500)}
							placeholder={({ imageProps, ref }) => (
								<div ref={ref} className="image selectable"></div>
							)}
							actual={({ imageProps }) => (
								<div 
									className="image" 
									style={{ backgroundImage: `url(${imageProps.src})` }} 
								>
									{this.state.hoverGrid &&
										<div className="hover">
											<div>
												<i className={`fal ${this.props.icon ? this.props.icon : 'fa-eye'}`}></i>
											</div>
										</div>
									}
								</div>
							)}
						/>
					</div>
					<div className="info">
						<h4>
							<Truncate text={topic.title} truncateAt={100} />
						</h4>
						
						{topic.completed && topic.quiz_pass_rate && 
							<small>
								{(parseFloat(topic.score) >= parseFloat(topic.quiz_pass_rate)) ? 'Pass' : 'Fail'}
								
								{topic.certificate === 1 && (parseFloat(topic.score) >= parseFloat(topic.quiz_pass_rate)) && 
									<i className="fal fa-trophy"></i>
								}
							</small>
						}
					</div>
					{topic.summary && 
						<div className="sub">
							<Truncate text={topic.summary} truncateAt={100} />
						</div>
					}
				</Link>
	        </div>
		);
	}
}

function mapStateToProps({ brand }, ownProps){
	return {
		brand
	};
}

export default withRouter(connect(mapStateToProps, { 
	
})(TopicGridItem));