import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import TopicsToolbar from './toolbar';
import AssetPicker from '../../components/assets/picker';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import { fetchTopicsList, saveTopicGeneral } from '../../actions/topics';
import { fetchCategoriesList } from '../../actions/categories';

import _ from 'lodash';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';
import moment from 'moment-timezone';
import 'moment/min/locales';

var formName = 'formTopicSetup';

class TopicSetup extends Component {

	constructor(props){
		super(props);
						
		const { id } = this.props.match.params;
		
		this.state = {
            id: id,
            status: 'draft', 
            date_launch: moment.tz(this.props.account.profile.timezone).add(1, 'hour').toDate(),
			public: true,
			embargo: false,
			quizzes: false,
			certificate: false,
			image: false,
			categories: [],
			dependencies: []
        }
        
        if(id){
	       	// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'TOPICS_EDIT')){
				this.props.history.push('/modules');
			}
			
			this.props.fetchTopicsList(id, () => {
		       	
		       	if(this.props.topics.topics && !this.props.topics.topics[id]){
					this.props.history.push('/modules');
				}else{
					this.updateDomElements();
				}
	       	});
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'TOPICS_ADD')){
				this.props.history.push('/modules');
			}
			
			this.updateDomElements();
		}			
		
		this.props.fetchTopicsList();
		this.props.fetchCategoriesList();
		
		moment.locale(i18n.language);
		this.props.stopSubmission(formName);		   
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
		
		let init = {
			status: this.state.status,
			date_launch: this.state.date_launch
		}
		
		if(this.props.topics.topics && this.state.id && this.props.topics.topics[this.state.id]){
		
			const topic = this.props.topics.topics[this.state.id];			
			let date_launch = moment.utc(topic.date_launch.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate();
			let date_embargo = topic.date_embargo ? moment.utc(topic.date_embargo.date, 'YYYY-MM-DD HH:mm:ss').tz(this.props.account.profile.timezone).toDate() : false;
			
			init = topic;
			init.date_launch = date_launch;
			init.date_embargo = date_embargo;
				
			this.setState({
				date_launch: date_launch,
				status: topic.status,
				public: topic.public === 1 ? true : false,
				embargo: date_embargo,
				quizzes: topic.quiz_pass_rate === null ? false : true,
				certificate: topic.certificate === 1 ? true : false,
				image: topic.image_id ? topic.image_id : false,
				categories: _.map(topic.categories, (cat, key) => { return cat.category_id }),
				dependencies: _.map(topic.dependencies, (dependency, key) => { return dependency.dependent_topic_id }),
			});	
			
			this.props.setSectionOptions(
				'modules', 
				topic.title,
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
			
		}else{
			this.props.setSectionOptions(
				'modules', 
				i18n.t('topics:settings_title'),
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
		}
		
		this.props.initialize(init);
	}
	
	onSubmit(values) {
				  	
	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  		  	
	  	posting.status = this.state.status;
	  	posting.date_launch = moment(this.state.date_launch).format('YYYY-MM-DD HH:mm:ss');
	  	posting.public = this.state.public ? 1 : 0;
	  	posting.image = this.state.image;
	  	//posting.embargo = (this.state.public && this.state.embargo) ? moment(this.state.embargo).format('YYYY-MM-DD HH:mm:ss') : false;
	  	
		posting.categories = _.map(this.state.categories, (value, key) => {
			return value.value ? value.value : value;
		});
		
		posting.dependencies = _.map(this.state.dependencies, (value, key) => {
			return value.value ? value.value : value;
		});
		  
	  	if(this.state.quizzes){
		  	posting.quizzes = {
			  	pass: values.quiz_pass_rate,
			  	certificate: this.state.certificate ? 1 : 0
		  	}
	  	}
	  	
	  	this.props.saveTopicGeneral(formName, this.state.id, posting, (ret) => {
			
			this.props.setSectionOptions(
				'topics', 
				ret.topics[0].title,
				[
					{
						url: 'todo',
						label: 'todo'
					}
				], 
				'/modules'
			);
			
			if(this.props.proceed){
				this.props.history.push(`/modules/${ret.topics[0].id}/lessons`);	
			}
		});
	}
	
	onInputChangeCategory(selected) {
		this.setState({ categories: selected });
	}
	
	onInputChangeDependencies(selected) {
		this.setState({ dependencies: selected });
	}
	
	renderPage(){
		
		const { topics } = this.props;
		
		if(this.state.id && (!topics.topics || !topics.topics[this.state.id])){
			return (
				<Loading />
			);
		}
		
		const topic = this.state.id ? topics.topics[this.state.id] : false;
		
		let categories = [];
		
		if(this.props.categories.categories){
						
			const findChildren = (parent, depth) => {
						
				const children = _.sortBy(_.filter(this.props.categories.categories, { parent_id: parent }), ['sortorder']);
						
				if(children.length > 0){
										
					children.forEach((value, key) => {
						
						let label = value.name;
						
						if(depth > 0){
							label = '- '.repeat(depth) + label;
						}
													
						categories.push({
							value: value.id,
							label: value.name,
							labelDropdown: label
						});
						
						findChildren(value.id, depth+1)
					});					
				}
			}
											
			findChildren(null, 0);
		}
		
		let dependencies = [];
		
		_.forEach(this.props.topics.topics, (value, key) => {
			
			if(!this.state.id || (this.state.id && value.id !== parseFloat(this.state.id))){
				
				if(['approved','draft','archived'].includes(value.status)){
				
					dependencies.push({
						value: value.id,
						label: value.title
					});
				}
			}									
		});
				
		return (
			<div className="container">				
				<div className="row">
					<div className="col-xl-8 col-md-12">
					
						<div className="c-card">
														
							<h4 className="u-mb-medium">{i18n.t('topics:settings_title_general')} </h4>				
		
							<Field
								label={i18n.t('topics:settings_label_title')} 
								name="title"
								type="text"
								component={InputField}
							/>
							
							<div className="row">
								<div className="col-xl-6 col-md-12">
									
									<Field
										label={i18n.t('topics:settings_label_status')} 
										name="status"
										type="suggest"
										options={[{
											label: 'Draft',
											value: 'draft'
										},
										{
											label: 'Approved',
											value: 'approved'
										},
										{
											label: 'Archived',
											value: 'archived'
										},
										{
											label: 'Disabled',
											value: 'disabled'
										}]}
										selected={this.state.status}
										onChangeFunc={(selected) => {
											this.setState({
												status: selected.value
											});
										}}
										component={InputField}
									/>
								</div>
								<div className="col-xl-6 col-md-12">
									<Field
										label={i18n.t('topics:settings_label_date_launch')} 
										name="date_launch"
										type="datepicker"
										timepicker={true}
										disabled={this.state.status === 'disabled' ? true : false}
										selected={this.state.date_launch}
										onChangeFunc={(date) => { 
											this.setState({
												'date_launch': date
											}); 
										}}
										component={InputField}
										minDate={this.state.embargo ? this.state.embargo : moment().toDate()}
									/>	
								</div>
							</div>
							
							<Field
								label={i18n.t('topics:settings_label_summary')} 
								name="summary"
								type="textarea-auto"
								maxLength={250}
								maxLengthCounter={true}
								component={InputField}
							/>
							
							<Field
								label={i18n.t('topics:settings_label_categories', { count: this.state.categories.length })} 
								name="categories"
								type="suggest"
								options={categories}
								component={InputField}
								multiple={true}
								selected={this.state.categories}
								onChangeFunc={this.onInputChangeCategory.bind(this)}
							/>
						</div>
					</div>	
					<div className="col-xl-4 col-md-12">
						<div className="c-card">
							<h4 className="u-mb-medium">{i18n.t('topics:settings_title_thumbnail')}</h4>
														
							<AssetPicker
								types={['image']}
								max={1}
								selected={this.state.image ? [{ type: 'asset', id: this.state.image }] : false}
								placeholder={i18n.t('topics:settings_thumbnail_empty')}
								onSelect={(selected) => {
									
									let image = false;
																		
									if(selected && selected.length > 0){
										image = selected[0].id;
									}
																		
									this.setState({
										image: image
									});
								}}
							/>					
						</div>
					</div>
				</div>
					
					
						
				<div className="row">
					<div className="col-xl-4 col-md-4">
						<div className="c-card equalize">
							<h4 className="u-mb-medium">{i18n.t('topics:settings_title_access')}</h4>
							
							<Field
								name="public"
								type="switch"
								labelSwitch={i18n.t('topics:settings_label_public')} 
								selected={this.state.public}
								onChangeFunc={(event) => {
									this.setState({
										public: event.target.checked
									});							
								}}
								component={InputField}
							/>
							
							{/*this.state.public && 
																	
								<React.Fragment>
									<Field
										name="embargo"
										type="switch"
										labelSwitch={i18n.t('topics:settings_label_embargo')} 
										selected={this.state.embargo}
										onChangeFunc={(event) => {
											
											let embargo = false;
											
											if(event.target.checked){
												embargo = moment().toDate(); 
											}
											
											this.setState({
												embargo: embargo
											});	
																	
										}}
										component={InputField}
									/>
									
									{this.state.embargo && 
							
										<Field
											name="date_embargo"
											type="datepicker"
											timepicker={true}
											selected={this.state.embargo}
											onChangeFunc={(date) => { 
												this.setState({
													embargo: date
												}); 
											}}													
											component={InputField}
											minDate={moment().toDate()}
											maxDate={this.state.date_launch}
										/>	
									}
									
									
								</React.Fragment>
							*/}			
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="c-card equalize">
							<h4 className="u-mb-medium">{i18n.t('topics:settings_title_quizzes')}</h4>
							
							<Field
								name="quizzes"
								type="switch"
								labelSwitch={i18n.t('topics:settings_label_quizzes')} 
								selected={this.state.quizzes}
								onChangeFunc={(event) => {
									this.setState({
										quizzes: event.target.checked
									});							
								}}
								component={InputField}
							/>
							
							{this.state.quizzes && 
								<div>
									<hr className="splitter u-mb-small" />
									
									<Field
										label={i18n.t('topics:settings_label_pass_percent')} 
										name="quiz_pass_rate"
										type="number"
										append="%"
										component={InputField}
									/>
																		
									<Field
										name="certificate"
										type="switch"
										labelSwitch={i18n.t('topics:settings_label_certificate')} 
										selected={this.state.certificate}
										onChangeFunc={(event) => {
											this.setState({
												certificate: event.target.checked
											});							
										}}
										component={InputField}
									/>
								</div>
							}							
						</div>
					</div>
					<div className="col-xl-4 col-md-6">
						<div className="c-card equalize">
							<h4 className="u-mb-medium">{i18n.t('topics:settings_title_dependencies')}</h4>
							
							<Field
								name="dependencies"
								type="suggest"
								options={dependencies}
								component={InputField}
								multiple={true}
								selected={this.state.dependencies}
								onChangeFunc={this.onInputChangeDependencies.bind(this)}
							/>						
						</div>
					</div>
				</div>					
			</div>
		);
	}
	
	// TODO: option here for cover image to be selected
	
	render() {		
				
		const { handleSubmit } = this.props;
		const { topics } = this.props;
					
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
			
				<TopicsToolbar 
					formName={formName}
					id={this.state.id}
					section="setup"
					history={this.props.history}
					topic={(this.state.id && topics.topics && topics.topics[this.state.id]) ? topics.topics[this.state.id] : false}
				/>
				
				{this.renderPage()}
			</form>				
		);
	}
}

const validate = (values, props) => {	
		
	const errors = {};
	
	if (!values.title) {
		errors.title = 'Enter a topic title!';
	}
	
	if (!values.status) {
		errors.status = 'Choose topic status!';
	}
	
	if (!values.date_launch) {
		errors.date_launch = 'Choose topic launch date!';
	}

	if (!values.summary) {
		errors.summary = 'Enter a topic summary!';
	}
		
	if (!values.quiz_pass_rate) {
		errors.quiz_pass_rate = 'Choose a percentage pass rate required!';
	}else if(values.quiz_pass_rate < 0 || values.pass_percentage > 100){
		errors.quiz_pass_rate = 'Choose a valid percentage pass rate!';
	}
	
	return errors;
}

function mapStateToProps({ topics, forms, account, categories }, ownProps){
	return {
		topics,
		proceed: forms.proceed,
		account,
		categories
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchTopicsList,
		saveTopicGeneral,
		fetchCategoriesList
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(TopicSetup)
);
