import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import { fetchAssetsList, saveAsset } from '../../actions/assets';
import { makeSelection } from '../../actions/library';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import AssetGridItem from '../../components/assets/grid_item';
import EmptySection from '../../components/chrome/empty';
import DropzoneArea from '../../components/forms/dropzone';
import { generateBack } from '../../helpers/back';
import { startSubmission } from '../../actions/forms';
import Button from '../../components/chrome/button';

var formName = 'formLibrary';

class Library extends Component {

	constructor(props){
		super(props);
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		let section = 'selected';
		
		if(queryString.type){
			section = queryString.type;
		}
					
		this.state = {
			type: section,
			search: false,
			keyword: false,
			title: i18n.t('library:title'),
			types: false,
			max: false,
			selected: false
		}
		
		this.props.fetchAssetsList();
	}
	
	componentWillReceiveProps(nextProps){
		
		if(nextProps.library.selected !== 'undefined' && nextProps.library.max !== 'undefined' && nextProps.library.types !== 'undefined'){
			
			this.setState({
				types: nextProps.library.types,
				max: nextProps.library.max,
				selected: nextProps.library.selected
			});
		}		
	}
	
	onChangeType(type){
		this.setState({
			type: type
		});
	}
	
	onSelectItem(type, id){	
		let action = (_.find(this.props.library.selected, { 'type': type, 'id': id }) ? 'delete' : 'add');		
		this.props.makeSelection(type, [{ id: id }], action);
	}
	
	filterData(retType){
		
		let type = false;
		let assets = [];
		let counter = {
			image: 0,
			video: 0,
			audio: 0,
			document: 0,
			selected: 0
		};
		
		_.forEach(this.props.assets.assets, (asset, key) => {	
			
			type = asset.type;
			
			switch(type){
				
				case 'youtube':
				case 'vimeo':
					type = 'video';	
					break;
					
				case 'pdf':
				case 'document':
				case 'presentation':
				case 'spreadsheet':
					type = 'document';	
					break;
					
				case 'audio':
					type = 'audio';	
					break;
					
				default:
				
					break;
			} 	
											
			if(this.state.search && this.state.keyword){
				
				if(asset.name !== '' && asset.name !== null && asset.name.toLowerCase().includes(this.state.keyword.toLowerCase())){
					
					if(this.state.type === type){
						assets.push(asset);
					}
					
					if(_.find(this.props.library.selected, { 'type': 'asset', 'id': asset.id })){
						
						if(this.state.type === 'selected'){
							assets.push(asset);
						}
						
						++counter['selected'];
					}
					
					++counter[type];
				}
				
			}else{
				
				if(this.state.type === type){
					assets.push(asset);
				}
				
				if(_.find(this.props.library.selected, { 'type': 'asset', 'id': asset.id })){
						
					if(this.state.type === 'selected'){
						assets.push(asset);
					}
					
					++counter['selected'];
				}
				
				++counter[type];
			}

		}, this);
				
		
		if(retType === 'counter'){
			return counter;
		}else{
			return assets;
		}
	}
	
	renderFiles(){
		
		let assets = this.filterData();
					
		if(_.isEmpty(assets)){

			let action_image = {
				label: i18n.t('library:action_add_image'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_video = {
				label: i18n.t('library:action_add_video'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_audio = {
				label: i18n.t('library:action_add_audio'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=audio', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			let action_document = {
				label: i18n.t('library:action_add_document'),
				url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=document', 'library', window.location.pathname + '?type=' + this.state.type)}`
			};
			
			switch(this.state.type){
				
				case 'image':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_image')}
							description={i18n.t('library:empty_description_image')}
							cta={action_image}
						/>
					);
					break;
					
				case 'video':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_video')}
							description={i18n.t('library:empty_description_video')}
							cta={action_video}
						/>
					);
					break;
					
				case 'audio':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_audio')}
							description={i18n.t('library:empty_description_audio')}
							cta={action_video}
						/>
					);
					break;
					
				case 'document':
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_document')}
							description={i18n.t('library:empty_description_document')}
							cta={action_video}
						/>
					);
					break;
					
				default:
					return (
						<EmptySection
							icon="fa-exclamation-circle"
							title={i18n.t('library:empty_title_selected')}
							description={i18n.t('library:empty_description_selected')}
							cta={[
								this.renderButtonGroup('add'),
								this.renderButtonGroup('select')
							]}
						/>
					);
					break;
			}			
		}
		
		assets = _.orderBy(assets, ['created.date'],['desc']); 
				
		return (
         	<div className="cta-push">
			 	<div className="c-card u-pb-zero">
		         	<div className="row assets u-pb-zero u-pt-zero">
						{_.map(assets, (asset, key) => {
							
							let assetFinal = _.clone(asset);	
							
							let type = 'asset';
							let section = 'video';
														
							if(asset.type === 'image'){
								section = 'image'
							}else if(asset.type === 'audio'){
								section = 'audio';
							}else if(asset.type === 'document'){
								section = 'document';
							}
							
							let selected = _.find(this.props.library.selected, { 'type': type, 'id': asset.id });							
							let headlineChange = false;
							
							return (
								<AssetGridItem 
									key={key}
									asset={assetFinal}
									onSelectItem={() => { this.onSelectItem(type, assetFinal.id) }}
									selected={selected}
									size="small"
									back={generateBack(window.location.pathname + '?type=' + section, 'library', window.location.pathname + '?type=' + this.state.type)}
									actions={['edit']}
									icon={selected ? 'fa-minus' : 'fa-plus'}
									headlineChange={headlineChange}
									{...this.props}
								/>						
							);
						})}
					</div>
				</div>
			</div>
		);		
	}
	
	renderCta(){
		
		let buttons = [];
		
		switch(this.state.type){
			
			case 'image':
				buttons.push({
					label: i18n.t('library:action_add_image'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'video':
				buttons.push({
					label: i18n.t('library:action_add_video'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'audio':
				buttons.push({
					label: i18n.t('library:action_add_audio'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=audio', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'document':
				buttons.push({
					label: i18n.t('library:action_add_document'),
					url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=document', 'library', window.location.pathname + '?type=' + this.state.type)}`,
					outline: true
				});
				break;
				
			case 'selected':
				buttons.push(this.renderButtonGroup('add', 'cta'));
				buttons.push(this.renderButtonGroup('select', 'cta'));				
				break;
				
			default:
				break;
		}
		
		return (
			<div id="cta">
				{_.map(buttons, (button, key) => {
					
					return (
						<Button
							key={key}
							{...button}
						/>
					)
				})}
			</div>
		);	
	}
	
	renderButtonGroup(type, cta){
				
		let button = {
			split_reverse: cta ? cta : false,
			outline: cta ? cta : false
		};
		
		let types = {};
		
		switch(type){
			
			case 'add':
			
				types = {
					image: {
						label: i18n.t('library:action_add_image'),
						url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=image', 'library', window.location.pathname + '?type=' + this.state.type)}`
					},
					video: {
						label: i18n.t('library:action_add_video'),
						url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=video', 'library', window.location.pathname + '?type=' + this.state.type)}`
					},
					audio: {
						label: i18n.t('library:action_add_audio'),
						url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=audio', 'library', window.location.pathname + '?type=' + this.state.type)}`
					},
					document: {
						label: i18n.t('library:action_add_document'),
						url: `${this.props.parentPath}/asset?b=${generateBack(window.location.pathname + '?type=document', 'library', window.location.pathname + '?type=' + this.state.type)}`
					}
				};
				
				if(!this.state.types || this.state.types.includes('image')){
					button.label = types.image.label;
					button.url = types.image.url;
					delete(types.image);
				}else if(this.state.types.includes('video')){
					button.label = types.video.label;
					button.url = types.video.url;
					delete(types.video);
				}else if(this.state.types.includes('audio')){
					button.label = types.audio.label;
					button.url = types.audio.url;
					delete(types.audio);
				}else if(this.state.types.includes('document')){
					button.label = types.document.label;
					button.url = types.document.url;
					delete(types.document);
				}
				
				if(!_.isEmpty(types)){
					
					button.split = _.map(types, (value) => {
						return value;
					});
				}
				
				console.log(button)
				
				return button;
				break;
				
			case 'select':
				
				types = {
					image: {
						label: i18n.t('library:action_select_image'),
						onClick: () => { this.onChangeType('image') }
					},
					video: {
						label: i18n.t('library:action_select_video'),
						onClick: () => { this.onChangeType('video') }
					},
					audio: {
						label: i18n.t('library:action_select_audio'),
						onClick: () => { this.onChangeType('audio') }
					},
					document: {
						label: i18n.t('library:action_select_document'),
						onClick: () => { this.onChangeType('document') }
					},
				};
				
				if(!this.state.types || this.state.types.includes('image')){
					button.label = types.image.label;
					button.onClick = types.image.onClick;
					delete(types.image);
				}else if(this.state.types.includes('video')){
					button.label = types.video.label;
					button.onClick = types.video.onClick;
					delete(types.video);
				}else if(this.state.types.includes('audio')){
					button.label = types.audio.label;
					button.onClick = types.audio.onClick;
					delete(types.audio);
				}else if(this.state.types.includes('document')){
					button.label = types.document.label;
					button.onClick = types.document.onClick;
					delete(types.document);
				}
				
				if(!_.isEmpty(types)){
					
					button.split = _.map(types, (value) => {
						return value;
					});
				}
				
				console.log(button)
				
				return button;
				break;
				
			default:
			
				break;
		}
		
	}
	render() {
	
		if(!this.props.assets.assets){
			return (
				<Loading />
			);
		}
				
		const counter = this.filterData('counter');
		
		let tabs = [
			{
				label: i18n.t('library:toolbar_selected'),
				counter: counter.selected,
				onClick: () => { this.onChangeType('selected') },
				selected: this.state.type === 'selected' ? true : false
			}
		];
		
		if(!this.state.types || this.state.types.includes('image')){
			
			tabs.push({
				label: i18n.t('library:toolbar_image'),
				counter: counter.image,
				onClick: () => { this.onChangeType('image') },
				selected: this.state.type === 'image' ? true : false
			});
			
		}
		
		if(!this.state.types || this.state.types.includes('video')){
			
			tabs.push({
				label: i18n.t('library:toolbar_video'),
				counter: counter.video,
				onClick: () => { this.onChangeType('video') },
				selected: this.state.type === 'video' ? true : false
			})
		}
		
		if(!this.state.types || this.state.types.includes('audio')){
			
			tabs.push({
				label: i18n.t('library:toolbar_audio'),
				counter: counter.audio,
				onClick: () => { this.onChangeType('audio') },
				selected: this.state.type === 'audio' ? true : false
			})
		}
		
		if(!this.state.types || this.state.types.includes('document')){
			
			tabs.push({
				label: i18n.t('library:toolbar_document'),
				counter: counter.document,
				onClick: () => { this.onChangeType('document') },
				selected: this.state.type === 'document' ? true : false
			})
		}
		
		let label = 'library:library_action_submit';
		
		if(_.isEmpty(this.props.library.selected)){
			label = 'library:library_action_submit_empty';
		}
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{this.state.title}
					</h2>
				</header>
				
				<Toolbar
					offset={0}
					tabs={tabs}
					buttons={[
						{
							label: i18n.t(label, { count: this.props.library.selected ? this.props.library.selected.length : 0 }),
							onClick: () => { 
								this.props.startSubmission(formName);
								this.props.library.onSelect(this.props.library.selected);
								this.props.history.push(this.props.parentPath);							
							}
						}
					]}
					filter={{
						toggleFunc: (status) => {
							this.setState({
								search: status
							});
						},
						onChangeFunc: (event) => { 
							
							this.setState({
								keyword: event.target.value
							})
						},
						fields: [
							{
								placeholder: i18n.t('library:filter_keyword'),
								name: "keyword",
								type: 'text',
								value: this.state.keyword ? this.state.keyword : ''
							}
						],
						open: this.state.search
					}}
				/>
				
				<DropzoneArea
					type="multiple"
					processFiles={(files, callback, callbackError) => {
						
						let values = {
							collection_id: false,
							files: files,
							source: 'local'
						}

						this.props.saveAsset(false, false, values, (percent) => {
							
						}, (assets) => {
							callback();
							this.props.makeSelection(assets)
						}, () => { callbackError() });
					}}
				>	
					{this.renderFiles()}						
				</DropzoneArea>
					
				{this.renderCta()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, overlay, library }, ownProps){
	return {
		assets,
		overlay,
		library
	};
}

export default connect(mapStateToProps, { 
	fetchAssetsList,
	makeSelection,
	saveAsset,
	startSubmission
})(Library);