import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { imageResize } from '../../helpers/s3';
import InputField from '../../components/forms/field.js';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { fetchCategoriesList, saveSort } from '../../actions/categories';

import 'react-sortable-tree/style.css';
import SortableTree from 'react-sortable-tree';

class Categories extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'CATEGORIES')){
			this.props.history.push('/');
		}
				
		this.props.setSectionOptions(
			'categories', 
			i18n.t('categories:title')
		);
		
		this.props.fetchCategoriesList();
		
		this.state = {
			tree: [],
			reload: true
		}		
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
		this.updateTree(); 
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	componentWillReceiveProps(nextProps){
					
		if(nextProps.categories.categories){
			
			if(this.state.reload){
				this.updateTree(nextProps.categories.categories);
			}else{
				this.setState({ reload: true });
			}
		}		
	}
	
	updateTree(data){
		
		if(!data){
			
			if(!this.props.categories.categories){
				return;
			}
			
			data = this.props.categories.categories;
		}

		const findChildren = (parent) => {
			
			let ret = [];
			const children = _.sortBy(_.filter(data, { parent_id: parent }), ['sortorder']);

			if(children.length > 0){
				
				children.forEach((value, key) => {
											
					ret.push({
						id: value.id,
						title: value.name,
						children: findChildren(value.id)
					});
				});					
			}
			
			return ret;
		}
											
		this.setState({
			tree: findChildren(null)
		})
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	renderTable(){

		let data = this.props.categories.categories;
				
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`categories:empty_title`)}
					description={i18n.t(`categories:empty_description`)}
					cta={{
						label: i18n.t('categories:action_add'),
						url: '/categories/category/add'
					}}
				/>
			);			
		}
		
		let rowClick = (row) => {
			this.props.history.push(`/categories/category/${row.id}`);
		};
					
		return (
			<div className="container">
				<div className="c-card">
					
					<div style={{ height: '90vh' }}
						draggable="false"		
					>
						
						
						
						<SortableTree
							treeData={this.state.tree}
							onChange={(treeData) => {
								this.setState({
									tree: treeData
								});
							}}
							onMoveNode={(data) => {
							
								let posting = {}
																
								const loopChildren = (parent, children) => {
								
									let x = 1;
									
									if(children.length > 0){
														
										children.forEach((item) => {
															
											++x;
											
											posting[item.id] = {
											parent: parent,
											sort: x
											}
											
											if(item.children && item.children.length > 0){
												loopChildren(item.id, item.children);
											}
										});					
									}
								}
						
								let i = 0;
								
								data.treeData.forEach((item) => {
								
									++i;
									
									posting[item.id] = {
										parent: null,
										sort: i
									}
									
									if(item.children && item.children.length > 0){
										loopChildren(item.id, item.children);
									}							
								});						
						
								this.props.saveSort(posting, () => {
									this.setState({ reload: false });
									this.props.fetchCategoriesList();
								});
							}}
							generateNodeProps={(rowInfo) => {
								
								const { node } = rowInfo;
								
								let buttons = []
								
								if(checkPermission(this.props.account.permissions, 'CATEGORIES_EDIT')){
									
									buttons.push(
										<Link
											to={`/categories/category/${node.id}`}
											title="Edit"
										>
											<i className="fal fa-edit"></i>
										</Link>
									)
								}
								
								return {
									buttons: buttons
								}
							}}
						/>
					</div>			
				</div>
			</div>
		);
	}
	
	render() {
		
		let { categories } = this.props;
		
		if(!categories.categories){
			return (
				<Loading />
			);
		}
		
		return (

			<React.Fragment>
				<Toolbar
					tabs={false}
					buttons={[{
						label: i18n.t('categories:action_add'),
						url: '/categories/category/add'
					}]}
				/>
				
				{this.renderTable()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, categories }, ownProps){
	return {
		account,
		categories
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCategoriesList,
	saveSort
})(Categories);