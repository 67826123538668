import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import AssetItem from '../../components/assets/item';
import Button from '../../components/chrome/button';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import EmptySection from '../../components/chrome/empty';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';

import _ from 'lodash';
import { fetchQuizzesList, saveAnswers, startQuiz, setProgress } from '../../actions/quizzes';
import { fetchTopicsList } from '../../actions/topics';
import { showNotification } from '../../actions/notifications';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';
import moment from 'moment-timezone';
import 'moment/min/locales';

var formName = 'formQuizView';

class QuizView extends Component {

	constructor(props){
		super(props);
								
		let { id, topic } = this.props.match.params;
		const { module } = this.props.match.params;
				
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'QUIZZES_VIEW')){
			this.props.history.push('/quizzes');
		}
		
		this.state = {
			id: id,
			question: 0,
			module: module,
			module_topic: topic,
			answers: {},
			started: false
		}
		
		if(module && topic){
			
			topic = --topic;
			
			this.props.fetchTopicsList(module, () => {
		       	
		       	if(this.props.topics.topics && !this.props.topics.topics[module]){
					this.props.history.push('/modules');
				}else if(!this.props.topics.topics[module].modules[topic] || !this.props.topics.topics[module].modules[topic].quiz_id){
					this.props.history.push(`/modules/${module}`);
				}
								
				id = this.props.topics.topics[module].modules[topic].quiz_id;
				
				this.setState({
					id: id
				});
				
				this.props.fetchQuizzesList(id, () => {
		       	
			       	if(this.props.quizzes.quizzes && !this.props.quizzes.quizzes[id]){
						this.props.history.push('/modules');
					}else{
						this.updateDomElements();
					}
		       	});
	       	});
			
		}else{
			
			if(!id){
		       	this.props.history.push('/quizzes');
			}
			
			this.props.fetchQuizzesList(id, () => {
		       	
		       	if(this.props.quizzes.quizzes && !this.props.quizzes.quizzes[id]){
					this.props.history.push('/quizzes');
				}else{
					this.updateDomElements();
				}
	       	});
		}

		this.props.stopSubmission(formName);		    
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements(); 
		this.props.setProgress(0);
	}
	
	componentWillUnmount(){
		this.props.setProgress(false);
	}
		
	updateDomElements(){
				
		if(this.state.id){
			
			if(this.props.quizzes.quizzes && this.props.quizzes.quizzes[this.state.id]){
				
				const quiz = this.props.quizzes.quizzes[this.state.id];	
				
				if(!this.state.started && !quiz.started){
					
					this.setState({
						started: true
					}, () => {
						this.props.startQuiz(this.state.id);
					});
				}
				
				if(this.state.module){
					
					this.props.setSectionOptions(
						'modules', 
						quiz.title,
						[
							{
								url: '/modules',
								label: 'Modules'
							},
							{
								url: `/modules/${this.state.module}/${this.state.module_topic}`,
								label: this.props.topics.topics[this.state.module].title
							}
						]						
					);
				}else{
					this.props.setSectionOptions(
						'quizzes', 
						quiz.title,
						[
							{
								url: '/quizzes',
								label: 'Quizzes'
							}
						]
					);
				}	
				
				let next = 0;	
				let answers = this.state.answers;
				
				_.forEach(quiz.questions, (question, key) => {
					
					if(question.answer_id){
						answers[question.id] = question.answer_id;
						next = key+1;
					}
				});
					
				this.setState({
					answers: answers
				}, () => {
					this.updateProgress();
				});
				
				if(quiz.format == 'flow'){
					
					this.setState({
						question: next
					});
				}
	
			}else{
				this.props.setSectionOptions(
					'quizzes', 
					'Questions',
					[
						{
							url: '/quizzes',
							label: 'Quizzes'
						}
					]
				);
			}			
		}
	}
	
	updateProgress(){
		
		const quiz = this.props.quizzes.quizzes[this.state.id];
		let completed = 0;
		
		if(quiz.format == 'flow'){
			completed = (100/quiz.questions.length) * _.filter(quiz.questions, question => question.answer_id !== null).length;
		}else{
			completed = (100/quiz.questions.length) * _.filter(quiz.questions, question => this.state.answers[question.id]).length;
		}
					
		this.props.setProgress(completed);
	}
	
	onSubmit(values) {
		
		const quiz = this.props.quizzes.quizzes[this.state.id];
				
		let answers = {};

		if(quiz.format == 'flow'){
			answers[quiz.questions[this.state.question].id] = values[`question_${this.state.question}`];
		}else{
			_.forEach(values, (value, key) => {
				if(key.startsWith('question_')){
					answers[quiz.questions[parseFloat(key.substr(9))].id] = value;
				}
			});
		}
				
		this.props.saveAnswers(formName, this.state.id, answers, () => {
					
			if(quiz.format == 'flow' && quiz.answers == 'proceed'){
				
				let answer = _.find(quiz.questions[this.state.question].answers, { correct: 1 });
		
				if(answer.id == answers[quiz.questions[this.state.question].id]){
					
					this.props.showNotification(
						'success',
						'Correct Answer'
					);
					
				}else{
					
					this.props.showNotification(
						'error',
						'Incorrect Answer',
						`The correct answer was '${answer.answer}'`
					);
				}
			}
			
			if(quiz.questions.length > this.state.question+1){
				
				this.setState({
					question: this.state.question+1
				});
			}
			
			this.updateProgress();
		});
	}
	
	renderQuestion(number, question){
	
		let status = '';
		
		if(question.answer_id){
			
			if(question.correct == 1){
				status = 'correct';
			}else{
				status = 'incorrect';
			}
		}
		
		return (
			<div key={number} className="u-mb-medium question">
				<h6 className={`question-number ${status}`}>#{number+1}</h6>	
				<h4 className="u-mb-small">
					{question.question}
				</h4>				
				
				{question.content && 
					<div className="u-mb-small">{question.content}</div>
				}
				
				{question.asset_id && 
					<div className="u-mb-medium">
						<AssetItem
							asset={{
								id: question.asset_id,
								type: question.asset_type,
								filename: question.asset_url,
							}}
						/>
					</div>
				}
				
				<Field
					name={`question_${number}`}
					checked={this.state.answers[question.id]}
					options={_.map(question.answers, (answer, key) => {
						
						let label = answer.answer;
						let status = '';
		
						if(question.answer_id){
							
							const correct = _.find(question.answers, { correct: 1 });
							
							if(correct.id == answer.id){
								status = 'correct';
								
								if(question.answer_id == answer.id){
									label = (
										<React.Fragment>
											{label} <span className="c-badge c-badge--success">Correct</span>
										</React.Fragment>
									);
								}
								
							}else if(question.correct == 0 && question.answer_id == answer.id){
								status = 'incorrect';
								
								label = (
									<React.Fragment>
										{label} <span className="c-badge c-badge--danger">Incorrect</span>
									</React.Fragment>
								);
							}
						}
						
						return {
							value: answer.id,
							label: label,
							className: `wrapped ${status}`
						}
					
					})}
					type="radio"
					onChangeFunc={(e) => {
						
						let answers = this.state.answers;
						
						answers[question.id] = e.target.value;
						
						this.setState({
							answers: answers
						}, () => {
							this.updateProgress();
						});
					}}
					disabled={question.answer_id ? true : false}
					component={InputField}
				/>
			</div>
		);
	}
		
	renderPage(){
		
		const { quizzes } = this.props;
		
		if(!this.state.id || !quizzes.quizzes || !quizzes.quizzes[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const quiz = quizzes.quizzes[this.state.id];
		
		if(!quiz.started){
			
			return (
				<Loading />
			);
			
		}else if(quiz.completed){
			
			return (
				<div className="container" key="completed">	
					<div className="row">

						<div className="col-xl-4 col-md-12">
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small `}>
									<i className={`fal fa-calendar`}></i>
								</span>
										
								<h3 className="c-text--subtitle">Completed</h3>
								<h2 className="counter">
									{moment(quiz.completed.date).format('LLL')}
								</h2>
							</div>
						</div>
						<div className="col-xl-4 col-md-12">
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small `}>
									<i className={`fal fa-check`}></i>
								</span>
										
								<h3 className="c-text--subtitle">Correct</h3>
								<h2 className="counter">
									<CountUp 
										delay={0} 
										duration={1} 
										end={_.filter(quiz.questions, { correct: 1 }).length} 
										separator="," 
										preserveValue={true}
									/>/{quiz.questions.length}
								</h2>
							</div>
						</div>
						<div className="col-xl-4 col-md-12">
							<div className="c-card equalize stats-box">
								<span className={`c-icon u-mb-small `}>
									<i className={`fal fa-badge-percent`}></i>
								</span>
										
								<h3 className="c-text--subtitle">Result</h3>
								<h2 className="counter">
									<CountUp 
										delay={0} 
										duration={1} 
										end={quiz.score} 
										separator="," 
										preserveValue={true}
										suffix="%"
									/>
								</h2>
							</div>
						</div>
					</div>

					{quiz.answers == 'end' &&

						<div className="c-card u-mt-medium">	
							{_.map(quiz.questions, (item, key) => {
								
								const correct = _.find(item.answers, { correct: 1 });
								
								return this.renderQuestion(key, item)
							})}
						</div>
					}
							
					{(this.state.module && this.state.module_topic) && 
						<Link to={`/modules/${this.state.module}/${this.state.module_topic}`} className="c-btn c-btn--info u-mt-medium">
							Back to module
						</Link>
					}
				</div>
			);
		}else if(quiz.questions.length == 0){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`quizzes:empty_questions_title`)}
					description={i18n.t(`quizzes:empty_questions_description`)}
				/>
			);			
		}
		
		return (
			<div className="container">		
						
				{quiz.format == 'flow' && 
					<div className="c-card u-mt-medium" key={this.state.question}>
						{this.renderQuestion(this.state.question, quiz.questions[this.state.question])}
						
						<Button
							label={(quiz.questions.length == this.state.question+1) ? i18n.t('quizzes:action_quiz_submit') : i18n.t('quizzes:action_quiz_next')}
							submit={formName}
						/>
					</div>
				||
					<div className="c-card">
						{_.map(quiz.questions, (item, key) => {
							return this.renderQuestion(key, item);
						})}
						
						<Button
							label={i18n.t('quizzes:action_quiz_submit')}
							submit={formName}
						/>
					</div>
				}
			</div>
		)
	}
	
	render() {
	
		const { handleSubmit } = this.props;

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				{this.renderPage()}
			</form>				
		);
	}
}

const validate = (values, props) => {	
			
	const errors = {};
	
	let i = 0;
		
	while (i < 200) {
		
		if (!values[`question_${i}`]) {
			errors[`question_${i}`] = 'Please enter a question!';
		}
				
		i++;
	}

	return errors;
}

function mapStateToProps({ quizzes, account, topics }, ownProps){
	return {
		quizzes,
		account,
		topics
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchQuizzesList,
		fetchTopicsList,
		saveAnswers,
		startQuiz,
		showNotification,
		setProgress
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(QuizView)
);
