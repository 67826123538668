// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import InputField from '../../../components/forms/field.js';
import _ from 'lodash';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import Loading from '../../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import { userAccount } from '../../../actions/account';
import { predictCountry } from '../../../actions/users';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import { fetchRetailersList, saveRetailer, fetchRetailersGroups } from '../../../actions/retailers';
import { fetchTimezones } from '../../../actions/options';

var countries = require("i18n-iso-countries");
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

var formName = 'formRetailersSettings';

class RetailersSettings extends Component {

	constructor(props){
		super(props);
		
		let language = i18n.language.split('-');
		countries.registerLocale(require(`i18n-iso-countries/langs/${language[0]}.json`));
				
		let { id } = this.props.match.params;
		let own = false;
		
		if(id){
						
			if(id === 'add'){
				
				// MUST HAVE CORRECT PERMISSIONS!
				if(!checkPermission(this.props.account.permissions, 'RETAILERS_ADD')){
					this.props.history.push('/retailers');
				}
				
				id = false;
				
				this.props.fetchTimezones();
				this.props.predictCountry(() => {
					this.updateDomElements();
				});
								
			}else{
				
				// MUST HAVE CORRECT PERMISSIONS!
				if(!checkPermission(this.props.account.permissions, 'RETAILERS_EDIT')){
					this.props.history.push('/');
				}
				
				this.props.fetchRetailersList(id, () => {
		       	
			       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
						this.props.history.push('/retailers');
					}else{
						this.updateDomElements();
					}
		       	});		       	
			}
			
		}else{
									
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
				this.props.history.push('/');
			}
			
			own = true;
			id = this.props.account.group.id;
			
			this.props.fetchRetailersList(id, () => {
		       	
		       	if(this.props.retailers.retailers && !this.props.retailers.retailers[id]){
					this.props.history.push('/');
				}else{
					this.updateDomElements();
				}
	       	});
			
			this.props.setSectionOptions(
				'settings', 
				i18n.t('users:settings_title')
			);
		}
		
		let groups = checkPermission(this.props.account.permissions, id ? 'RETAILERS_GROUPS_ASSIGN' : 'RETAILERS_GROUPS_ASSIGN');
		
		if(groups){
			this.props.fetchRetailersGroups(false, () => {
				this.updateDomElements();
			});
		}
		
		this.state = {
			own: own,
			id: id,
			groups: groups,
			address: {
				address1: {
					value: ''
				},
				address2: {
					value: '',
					display: false
				},
				address3: {
					value: '',
					display: false
				}
			},
			telephone: '',
			group: [],
			country: false,
			language: language,
			map: false,
			zoom: 1,
			user_add: true,
			user_language: this.props.brand.details.languages.length == 1 ? this.props.brand.details.languages[0] : false
		}
				
		this.getAddressBouncer = _.debounce(() => {
			this.updateMapAddress();
		}, 250);
	}	
	
	updateDomElements(){
		
		if(this.props.retailers && this.props.retailers.retailers && this.state.id && this.props.retailers.retailers[this.state.id] && (!this.state.groups || (this.state.groups && this.props.retailers.groups))){
		
			let retailer = this.props.retailers.retailers[this.state.id];	
			let groups = [];
			
			if(this.state.groups){
				_.forEach(this.props.retailers.groups, (group, key) => {					
	
					if(group.retailers && group.retailers.includes(retailer.id)){
						groups.push(group.id);
					}
				}, this);
			}
			
			this.setState({
				group: groups,
				country: retailer.details.country ? retailer.details.country : '',
				telephone: retailer.details.telephone ? retailer.details.telephone : '',
				address: {
					address1: {
						value: retailer.details.address1 ? retailer.details.address1 : ''
					},
					address2: {
						value: retailer.details.address2 ? retailer.details.address2 : '',
						display: retailer.details.address1 ? true : false
					},
					address3: {
						value: retailer.details.address3 ? retailer.details.address3 : '',
						display: retailer.details.address2 ? true : false
					},
					city: {
						value: retailer.details.city ? retailer.details.city : ''
					},
					county: {
						value: retailer.details.county ? retailer.details.county : ''
					},
					postcode: {
						value: retailer.details.postcode ? retailer.details.postcode : ''
					}
				},
			});

			this.props.initialize({
				name: retailer.name,
				...retailer.details
			});	
			
			this.updateMapAddress();
		}
		
		if(!this.state.id && !this.state.country && this.props.users.country_prediction){
			this.onInputCountryChange(this.props.users.country_prediction);
			this.props.change('country', this.props.users.country_prediction);	
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateMapAddress();
		this.updateDomElements();
	}
	
	onSubmit(values) {

	  	this.props.startSubmission(formName);
	  	
	  	var posting = _.clone(values);
	  		  	
	  	if(this.state.group && this.state.group.length > 0){
		  	
		  	posting.groups = _.map(this.state.group, (group, key) => {					
			  	
			  	if(group.value){
				  	return group.value;
			  	}else{
				  	return group;
			  	}
			  	
			}, this);
			
	  	}else{
		  	posting.groups = [];
	  	}

	  	posting.country = this.state.country;
	  	
	  	
	  	if(this.state.user_add){
		  	
		  	posting.user = {
			  	forename: values.user_forename,
			  	surname: values.user_surname,
			  	email: values.user_email,
			  	language: this.state.user_language
		  	}
	  	}
	  	
	  	this.props.saveRetailer(formName, this.state.id, posting, (id) => {
						
			if(this.state.groups){
				this.props.fetchRetailersGroups(false, () => {
					this.setState({ id: id });
					this.updateDomElements();
				});
			}	
			
			if(this.state.own){
				this.props.userAccount();
			}else{
								
				if(this.state.id){
					this.props.history.push(`${this.props.parentPath}/retailer/${id}`);
				}else{
					this.props.history.push(`${this.props.parentPath}/retailer/${id}/branding`);
				}
			}		
		});
	}

	onInputGroupChange(selected){

		this.setState({
			group: selected
		});
	}
	
	onInputCountryChange(selected){
		
		console.log(selected);
		
		
		let country = selected;
		
		if(selected.value){
			country = selected.value;
		}
		
		this.setState({
			country: country
		});
		
		this.getAddressBouncer();
		
		if(this.state.user_add && !this.state.user_language){
		
			let timezone = _.find(this.props.options.timezones, {country_code: country});;
			
			if(timezone){
				
				if(this.props.brand.details.languages.includes(timezone.languages[0])){
					this.setState({
						user_language: timezone.languages[0]
					})
				}
			}
		}
	}
	
	onInputUserLanguageChange(selected){
				
		this.setState({
			user_language: selected.value
		});
	}
	
	onInputAddressChange(type, event){

		let address = this.state.address;
		
		if(!address[type]){
			address[type] = {};
		}
		
		address[type].value = event.target.value;
		
		if(address['address1'].value !== '' || address['address2'].value !== '' || address['address3'].value !== ''){
			address['address2'].display = true;
		}else{
			address['address2'].display = false;
		}
		
		if(address['address2'].value !== '' || address['address3'].value !== ''){
			address['address3'].display = true;
		}else{
			address['address3'].display = false;
		}
		
		this.setState({
			address: address
		});
		
		this.getAddressBouncer();
	}
	
	onInputTelephoneChange(value){
		
		this.setState({
			telephone: value
		});
	}
	
	onInputAddChange(event){
		
		this.setState({
			user_add: event.checked
		});
	}

	updateMapAddress(){
		
		let address = '';
		let display = true;
		
		if(this.state.address.address1.value !== ''){
			
			address = this.state.address.address1.value;
			
			if(this.state.address.address2.value !== ''){
				address += ', ' + this.state.address.address2.value;				
			}
			
			if(this.state.address.address3.value !== ''){
				address += ', ' + this.state.address.address3.value;				
			}
			
			if(this.state.address.city && this.state.address.city.value !== ''){
				address += ', ' + this.state.address.city.value;				
			}else{
				display = false;
			}
			
			if(this.state.address.county && this.state.address.county.value !== ''){
				address += ', ' + this.state.address.county.value;				
			}else{
				//display = false;
			}
			
			if(this.state.address.postcode && this.state.address.postcode.value !== ''){
				address += ', ' + this.state.address.postcode.value;				
			}else{
				display = false;
			}
			
		}else{
			display = false;
		}	
		
		let country = '';
		let zoom = 1;
		
		if(this.state.country){
			country = countries.getName(this.state.country, "en");	
		}
		
		if(display){
			address += ', ' + country;
			zoom = 15;
		}else{
			address = country; 
			zoom = 2;
		}
		
		this.setState({
			map: address,
			zoom: zoom
		})
	}
	
	renderMap(){
						
		return (
			<iframe width="100%" height="500" src={`https://maps.google.com/maps?q=${encodeURIComponent(this.state.map)}&z=${this.state.zoom}&output=embed&iwloc`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" className="retailerMap" title="Retailer Map"></iframe>
		);
	}
	
	renderPage(){
		
		let { retailers } = this.props;

		if(this.state.groups && !retailers.groups){
			return (
				<Loading />
			);
		}
		
		return (
			<div className={this.state.own ? 'container' : null}>
				<div className="row">
					<div className="col-xl-4 col-md-12">	
						<div className="c-card">
							
							<h4 className="u-mb-medium">{i18n.t('retailers:settings_title_general')} </h4>

							<Field
								label={i18n.t('retailers:settings_label_name')} 
								name="name"
								type="text"
								component={InputField}
							/>
							
							<Field
								label={i18n.t('retailers:settings_label_email')} 
								name="email"
								type="email"
								component={InputField}
							/>
															
							<Field
								label={i18n.t('retailers:settings_label_country')} 
								name="country"
								type="suggest"
								options={_.map(countries.getNames(this.state.language[0]), (country, key) => {
									
									return {
										label: country,
										value: key
									}
								})}
								selected={this.state.country}
								onChangeFunc={this.onInputCountryChange.bind(this)}
								component={InputField}
							/>
							
							{this.state.country && 
								<Field
									label={i18n.t('retailers:settings_label_telephone')} 
									name="telephone"
									type="tel"
									country={this.state.country}
									selected={this.state.telephone}
									localization={i18n.language}
									disableDropdown={true}
									onChangeFunc={this.onInputTelephoneChange.bind(this)}
									component={InputField}
								/>
							}
							
							{this.state.groups && 
								<Field
									label={i18n.t('retailers:settings_label_group', { count: this.state.group ? this.state.group.length : 1 })} 
									name="groups"
									type="suggest"
									options={_.map(this.props.retailers.groups, (group, key) => {
										
										return {
											label: group.name,
											value: group.id
										}
									})}
									selected={this.state.group}
									onChangeFunc={this.onInputGroupChange.bind(this)}
									multiple={true}
									component={InputField}
								/>
							}
							
							{checkPermission(this.props.account.permissions, 'RETAILERS_NOTES') && 
					
								<Field
									label={i18n.t('retailers:settings_label_notes')} 
									name="notes"
									type="textarea-auto"
									component={InputField}
								/>
							}
						</div>
					</div>
				
					<div className="col-xl-8 col-md-12">
						<div className="c-card">
							
							<h4 className="u-mb-medium">{i18n.t('retailers:settings_title_location')} </h4>
									
							<div className="row">
								<div className="col-xl-7 col-md-12">
								
									<Field
										placeholder={i18n.t('retailers:settings_placeholder_address1')} 
										name="address1"
										type="text"
										onChangeFunc={this.onInputAddressChange.bind(this, 'address1')}
										component={InputField}
									/>
									
									{this.state.address.address2.display && 
										<Field
											placeholder={i18n.t('retailers:settings_placeholder_address2')} 
											name="address2"
											type="text"
											onChangeFunc={this.onInputAddressChange.bind(this, 'address2')}
											component={InputField}
										/>
									}
									
									{this.state.address.address3.display && 
										<Field
											placeholder={i18n.t('retailers:settings_placeholder_address3')} 
											name="address3"
											type="text"
											onChangeFunc={this.onInputAddressChange.bind(this, 'address3')}
											component={InputField}
										/>
									}
									
									<Field
										placeholder={i18n.t('retailers:settings_placeholder_city')} 
										name="city"
										type="text"
										onChangeFunc={this.onInputAddressChange.bind(this, 'city')}
										component={InputField}
									/>
									
									<Field
										placeholder={i18n.t('retailers:settings_placeholder_county')} 
										name="county"
										type="text"
										onChangeFunc={this.onInputAddressChange.bind(this, 'county')}
										component={InputField}
									/>
									
									<Field
										placeholder={i18n.t('retailers:settings_placeholder_postcode')} 
										name="postcode"
										type="text"
										className="u-mb-zero"
										onChangeFunc={this.onInputAddressChange.bind(this, 'postcode')}
										component={InputField}
									/>
								</div>
								<div className="col-xl-5 col-md-12">
									{this.renderMap()}
								</div>
							</div>
						</div>
						
						{!this.state.id && 
							
							<div className="c-card">
							
								<h4>
									{i18n.t('retailers:settings_user_title')} 
									
									<div className="options" style={{ marginRight: "-10px" }}>
										<Field
											name="add_user"
											type="switch"
											selected={this.state.user_add}
											onChangeFunc={(event) => { this.onInputAddChange(event.target); } }
											component={InputField}
										/>
									</div>
								</h4>
								
								<p>
									{i18n.t('retailers:settings_user_intro')} 
								</p>
								
								{this.state.user_add && 
									
									<React.Fragment>
										<div className="row u-mt-medium">
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('retailers:user_label_forename')} 
													name="user_forename"
													type="text"
													component={InputField}
												/>
											</div>
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('retailers:user_label_surname')} 
													name="user_surname"
													type="text"
													component={InputField}
												/>
											</div>
										</div>
																		
										<div className="row">
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('retailers:user_label_email')} 
													name="user_email"
													type="email"
													component={InputField}
												/>
											</div>
											<div className="col-xl-6 col-md-12">
												<Field
													label={i18n.t('users:account_label_language')} 
													name="user_language"
													type="suggest"
													options={_.map(this.props.brand.details.languages, (language, key) => {
													
														return {
															label: i18n.t(`translation:language_${language}`),
															value: language,
															//imageDropdown: `/images/flags/${language}.svg`
														}
						
													})}
													sort="asc"
													selected={this.state.user_language}
													onChangeFunc={this.onInputUserLanguageChange.bind(this)}
													component={InputField}
												/>
											</div>									
										</div>
									</React.Fragment>
								}
							</div>
						}
					</div>
				</div>
			</div>
		);
	}

	render() {
			
		const { handleSubmit } = this.props;
		
		let { retailers } = this.props;

		if(!retailers.retailers){
			return (
				<Loading />
			);
		}
		
		let title = i18n.t('retailers:title_add_retailer');
		
		if(this.state.id && retailers.retailers[this.state.id]){
			title = retailers.retailers[this.state.id].name;
		}
		
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
					
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								{title}
							</h2>
						</header>
					</React.Fragment>
				}
				
				<RetailsDetailsToolbar
					formName={formName}
					section="settings"
					id={this.state.id}
					own={this.state.own}
					{...this.props}
				/>
				
				{this.renderPage()}
			</form>
		);
	}
}


const validate = (values) => {	
	
	const errors = {};

	if (!values.name) {
		errors.name = 'Enter regionl name!';
	}
	
	if (!values.email) {
		errors.email = 'Enter retailer email!';
	}
	
	if (!values.country) {
		errors.country = 'Choose retailer country!';
	}
	
	if (!values.user_forename) {
		errors.user_forename = 'Enter the users forename!';
	}
	
	if (!values.user_surname) {
		errors.user_surname = 'Enter the users surname!';
	}
	
	if (!values.user_email) {
		errors.user_email = 'Enter the users email!';
	}
	
	if (!values.user_language) {
		errors.user_language = 'Enter the users language!';
	}

	return errors;
}

function mapStateToProps({ account, retailers, options, brand, users }, ownProps){
	return {
		account,
		retailers,
		options,
		brand,
		users
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchRetailersList,
		saveRetailer,
		fetchRetailersGroups,
		userAccount,
		fetchTimezones,
		predictCountry
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(RetailersSettings)
);